var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  attrs: {
                    placeholder: _vm.$t("applications.enter-person-type-name", {
                      personType: _vm.$t("applications." + _vm.labelSlug)
                    }),
                    icon: "person",
                    required: "",
                    value: _vm.model.name,
                    name: "name",
                    label: _vm.$t("applications.person-type-name", {
                      personType: _vm.$t("applications." + _vm.labelSlug)
                    }),
                    rules: [
                      function(v) {
                        return (
                          !!v ||
                          _vm.$t("applications.person-type-name-required", {
                            personType: _vm.$t("applications." + _vm.labelSlug)
                          })
                        )
                      }
                    ]
                  },
                  on: {
                    blur: function($event) {
                      return _vm.inputUpdate("name", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c("form-text-field", {
                  attrs: {
                    value: _vm.model.phone,
                    label: _vm.$t("applications.person-type-phone", {
                      personType: _vm.$t("applications." + _vm.labelSlug)
                    }),
                    icon: "phone_iphone",
                    name: "phone",
                    required: "",
                    rules: [
                      function(v) {
                        return (
                          v != !!v ||
                          _vm.$t("applications.person-type-phone-required", {
                            personType: _vm.$t("applications." + _vm.labelSlug)
                          })
                        )
                      },
                      function(v) {
                        return (
                          v.length == 0 ||
                          v.length == 14 ||
                          _vm.$t("applications.person-type-phone-invalid", {
                            personType: _vm.$t("applications." + _vm.labelSlug)
                          })
                        )
                      }
                    ],
                    mask: "(###)-###-####",
                    "return-masked-value": "",
                    placeholder: _vm.$t(
                      "applications.enter-person-type-phone",
                      {
                        personType: _vm.$t("applications." + _vm.labelSlug)
                      }
                    )
                  },
                  on: {
                    blur: function($event) {
                      return _vm.inputUpdate("phone", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  attrs: {
                    placeholder: _vm.$t(
                      "applications.enter-person-type-email",
                      {
                        personType: _vm.$t("applications." + _vm.labelSlug)
                      }
                    ),
                    icon: "mail_outline",
                    value: _vm.model.email,
                    label: _vm.$t("applications.person-type-email", {
                      personType: _vm.$t("applications." + _vm.labelSlug)
                    }),
                    name: "email",
                    rules: [
                      function(v) {
                        return (
                          v.length == 0 ||
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                            v
                          ) ||
                          _vm.$t("applications.person-type-email-invalid", {
                            personType: _vm.$t("applications." + _vm.labelSlug)
                          })
                        )
                      }
                    ]
                  },
                  on: {
                    blur: function($event) {
                      return _vm.inputUpdate("email", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c("form-text-field", {
                  attrs: {
                    value: _vm.model.fax,
                    label: _vm.$t("applications.person-type-fax", {
                      personType: _vm.$t("applications." + _vm.labelSlug)
                    }),
                    icon: "phonelink",
                    rules: [
                      function(v) {
                        return (
                          v.length == 0 ||
                          v.length == 14 ||
                          _vm.$t("applications.person-type-fax-invalid", {
                            personType: _vm.$t("applications." + _vm.labelSlug)
                          })
                        )
                      }
                    ],
                    name: "fax",
                    mask: "(###)-###-####",
                    "return-masked-value": "",
                    placeholder: _vm.$t("applications.enter-person-type-fax", {
                      personType: _vm.$t("applications." + _vm.labelSlug)
                    })
                  },
                  on: {
                    blur: function($event) {
                      return _vm.inputUpdate("fax", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }