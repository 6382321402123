<template>
    <div>
        <!-- Landlord Name / Phone -->
        <form-element-group>
            <template v-slot:col-1>
                <form-text-field
                    :placeholder="
                        $t(`applications.enter-person-type-name`, {
                            personType: $t(`applications.${labelSlug}`),
                        })
                    "
                    icon="person"
                    required
                    :value="model.name"
                    name="name"
                    :label="
                        $t(`applications.person-type-name`, {
                            personType: $t(`applications.${labelSlug}`),
                        })
                    "
                    :rules="[
                        (v) =>
                            !!v ||
                            $t(`applications.person-type-name-required`, {
                                personType: $t(`applications.${labelSlug}`),
                            }),
                    ]"
                    @blur="inputUpdate('name', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    :value="model.phone"
                    :label="
                        $t(`applications.person-type-phone`, {
                            personType: $t(`applications.${labelSlug}`),
                        })
                    "
                    icon="phone_iphone"
                    name="phone"
                    required
                    :rules="[
                        (v) =>
                            v != !!v ||
                            $t(`applications.person-type-phone-required`, {
                                personType: $t(`applications.${labelSlug}`),
                            }),
                        (v) =>
                            v.length == 0 ||
                            v.length == 14 ||
                            $t(`applications.person-type-phone-invalid`, {
                                personType: $t(`applications.${labelSlug}`),
                            }),
                    ]"
                    mask="(###)-###-####"
                    return-masked-value
                    :placeholder="
                        $t(`applications.enter-person-type-phone`, {
                            personType: $t(`applications.${labelSlug}`),
                        })
                    "
                    @blur="inputUpdate('phone', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
        <form-element-group>
            <template v-slot:col-1>
                <form-text-field
                    :placeholder="
                        $t(`applications.enter-person-type-email`, {
                            personType: $t(`applications.${labelSlug}`),
                        })
                    "
                    icon="mail_outline"
                    :value="model.email"
                    :label="
                        $t(`applications.person-type-email`, {
                            personType: $t(`applications.${labelSlug}`),
                        })
                    "
                    name="email"
                    :rules="[
                        (v) =>
                            v.length == 0 ||
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                v
                            ) ||
                            $t(`applications.person-type-email-invalid`, {
                                personType: $t(`applications.${labelSlug}`),
                            }),
                    ]"
                    @blur="inputUpdate('email', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    :value="model.fax"
                    :label="
                        $t(`applications.person-type-fax`, {
                            personType: $t(`applications.${labelSlug}`),
                        })
                    "
                    icon="phonelink"
                    :rules="[
                        (v) =>
                            v.length == 0 ||
                            v.length == 14 ||
                            $t(`applications.person-type-fax-invalid`, {
                                personType: $t(`applications.${labelSlug}`),
                            }),
                    ]"
                    name="fax"
                    mask="(###)-###-####"
                    return-masked-value
                    :placeholder="
                        $t(`applications.enter-person-type-fax`, {
                            personType: $t(`applications.${labelSlug}`),
                        })
                    "
                    @blur="inputUpdate('fax', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
    </div>
</template>
<script>
export default {
    name: "landlord-employer",
    components: {},
    props: {
        modelData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            model: this.modelData,
            labelSlug: this.modelData.label.toLowerCase().replace(" ", "-"),
        };
    },
    watch: {
        modelData() {
            this.model = this.modelData;
        },
    },
    methods: {
        inputUpdate(key, data) {
            this.model[key] = data;
            this.$emit("update", this.model);
        },
    },
};
</script>

<style scoped>
</style>